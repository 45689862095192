import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export interface HelperTextProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  variant?: 'default' | 'error';
  text: string | ReactNode;
}

export function HelperText({
  text,
  variant = 'default',
  className,
}: HelperTextProps) {
  if (!text) {
    return null;
  }
  return (
    <p
      className={twMerge(
        clsx(
          'text-xs font-medium text-neutral-500',
          variant === 'error' && 'text-primary-500'
        ),
        className
      )}
    >
      {text}
    </p>
  );
}
