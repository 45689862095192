import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { VerificationInput } from '../VerificationInput';

export interface PhoneVerificationProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  disabled?: boolean;
}

export const PhoneVerification = <T extends FieldValues>({
  name,
  control,
  disabled,
}: PhoneVerificationProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <VerificationInput
          // @ts-ignore
          onChange={field.onChange}
          hasError={!!fieldState.error}
          disabled={disabled}
        />
      )}
      rules={{ required: true }}
    />
  );
};

PhoneVerification.displayName = 'PhoneVerification';
