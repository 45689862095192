import React, { forwardRef, HTMLAttributes } from 'react';

import { TextInput } from '@components/atoms/TextInput';

export interface VerificationInputFieldProps {
  name: string;
  value: string;
  hasError?: boolean;
  disabled?: boolean;
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  handleChange: (name: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  handleKeyUp: (name: string, e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleKeyDown: (
    name: string,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void;
}

export const VerificationInputField = forwardRef<
  HTMLInputElement,
  VerificationInputFieldProps
>(
  (
    {
      name,
      value,
      handleChange,
      handlePaste,
      handleKeyUp,
      handleKeyDown,
      inputMode,
      ...rest
    },
    ref
  ) => {
    return (
      <TextInput
        maxLength={1}
        ref={ref}
        className="w-8 px-0 text-center sm:w-16"
        value={value}
        onChange={(e) => handleChange(name, e)}
        onPaste={handlePaste}
        onFocus={(e) => {
          e.target.setSelectionRange(0, value.length);
        }}
        onKeyUp={(e) => handleKeyUp(name, e)}
        onKeyDown={(e) => handleKeyDown(name, e)}
        inputMode={inputMode}
        {...rest}
      />
    );
  }
);

VerificationInputField.displayName = 'VerificationInputField';
