import { useEffect, useRef, useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { useElementDimensions } from 'epromo-lib/hooks';
import { Endpoints, useGetQuery } from 'epromo-lib';
import { CountryCodeItem } from 'epromo-types';

import { CountryCodesPopover } from '@components/molecules/CountryCodesPopover';
import { TextInput } from '@components/atoms/TextInput';
import { checkValue } from '@components/organisms/VerificationInput';

const extractPhoneParts = (
  phoneNumber: string,
  countryOptions?: CountryCodeItem[]
) => {
  if (phoneNumber && countryOptions) {
    const countryCode = countryOptions.find(
      ({ code }) => phoneNumber.indexOf(code) === 0
    );
    const phone = phoneNumber.slice(countryCode?.code.length);
    return [countryCode?.code, phone];
  }
  return ['', phoneNumber];
};
export interface PhoneInputProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean;
  hasError?: boolean;
}

export function PhoneInput<T extends FieldValues>({
  name,
  control,
  disabled,
  hasError,
}: PhoneInputProps<T>) {
  const [countryCode, setCountryCode] = useState('');

  const { data, isFetched } = useGetQuery<Array<CountryCodeItem>>(
    {
      queryKey: ['countryCodes'],
      endPoint: Endpoints.countryCodes,
      refetchOnWindowFocus: false,
      select: (countries) => {
        const defaultCountries = countries.filter(
          (country) => country.isDefault
        );
        const restCountries = countries.filter((country) => !country.isDefault);
        return [...defaultCountries, ...restCountries].filter(
          (country) => country.code !== '+'
        );
      },
    },
    undefined
  );

  useEffect(() => {
    if (isFetched && !!data?.length) {
      const defaultCountries = data.filter((country) => country.isDefault);
      if (defaultCountries[0]) {
        setCountryCode(defaultCountries[0].code);
      }
    }
  }, [isFetched, data]);

  const inputRef = useRef<HTMLInputElement>(null);
  const { width } = useElementDimensions(inputRef);

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const [code, phone] = extractPhoneParts(field.value, data);

          return (
            // eslint-disable-next-line max-len
            <div className="relative mt-1 flex items-center rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <CountryCodesPopover
                  disabled={disabled}
                  panelWidth={width}
                  codes={data}
                  value={code || countryCode}
                  onChange={(value) => {
                    setCountryCode(value);
                    if (phone && value) {
                      field.onChange(`${value}${phone}`);
                    } else {
                      field.onChange('');
                    }
                  }}
                />
              </div>
              <TextInput
                ref={inputRef}
                className="pl-24"
                type="tel"
                name={name}
                disabled={disabled}
                hasError={hasError}
                value={phone}
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  if (!checkValue(value, 'number', value.length)) return;
                  if (e.currentTarget.value && countryCode) {
                    field.onChange(`${countryCode}${e.currentTarget.value}`);
                  } else {
                    field.onChange('');
                  }
                }}
              />
            </div>
          );
        }}
      />
    </div>
  );
}
