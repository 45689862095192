import classNames from 'clsx';

export type FormLabelProps = {
  id: string;
  label?: string;
  isRequired?: boolean;
};

export function FormLabel({ id, label, isRequired }: FormLabelProps) {
  if (!label) {
    return null;
  }
  return (
    <label
      htmlFor={id}
      className={classNames(
        'block text-xs font-bold text-neutral-500',
        'mb-1',
        {
          "before:content-['*']": isRequired,
          'before:mr-0.5': isRequired,
        }
      )}
    >
      {label}
    </label>
  );
}
