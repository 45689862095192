import * as yup from 'yup';

import { I18 } from '../../../utils';

export const getVerifyPhoneSchema = (t: I18) => {
  const required = t('required', { ns: 'common' });
  return yup.object().shape({
    code: yup
      .string()
      .required(required)
      .max(6, t('maxSymbols', { ns: 'common', count: 6 })),
  });
};
