import React, { useState, createRef, useEffect, useCallback } from 'react';
// eslint-disable-next-line max-len
import { VerificationInputField as Input } from '../../molecules/VerificationInputField/VerificationInputField';

const DEFAULT_LENGTH = 6;

const validChars: Record<string, string> = {
  number: '0-9',
  alphanumeric: 'a-zA-Z0-9',
  mixed: "\\w\\[\\]`!@#$%^&*()={}:;<>+'-'\"",
};

const inputMode: Record<
  string,
  React.HTMLAttributes<HTMLInputElement>['inputMode']
> = {
  number: 'numeric',
  alphanumeric: 'text',
  mixed: 'text',
};

export const checkValue = (value: string, mode: Mode = 'number', length = 1) =>
  RegExp(`^[${validChars[mode]}]{0,${length}}$`).test(value);

const onInputChange = (
  name: string,
  e: React.ChangeEvent<HTMLInputElement>,
  inputConfig: InputConfig[],
  setInputConfig: React.Dispatch<React.SetStateAction<InputConfig[]>>,
  mode: Mode
) => {
  const value = e.target.value.replace(/\s/g, '');
  if (checkValue(value, mode) || value === '') {
    const previous = [...inputConfig];
    const idx = inputConfig.findIndex((x) => x.name === name);
    previous[idx].value = value;
    setInputConfig(previous);
  }
};

const onKeyUp = (
  name: string,
  e: React.KeyboardEvent<HTMLInputElement>,
  inputConfig: InputConfig[],
  focusNext: (idx: number) => void,
  mode: Mode
) => {
  e.preventDefault();
  const { key } = e;
  if (checkValue(key, mode)) {
    const idx = inputConfig.findIndex((x) => x.name === name);
    focusNext(idx);
  }
};

const onDelete = (
  name: string,
  e: React.KeyboardEvent<HTMLInputElement>,
  inputConfig: InputConfig[],
  setInputConfig: React.Dispatch<React.SetStateAction<InputConfig[]>>
) => {
  const { key } = e;
  if (key === 'Backspace') {
    const idx = inputConfig.findIndex((x) => x.name === name);
    if (idx > 0 && !inputConfig[idx].value) {
      const previous = [...inputConfig];
      previous[idx - 1].inputRef?.current?.focus();
      previous[idx - 1].value = '';
      setInputConfig(previous);
    } else {
      const previous = [...inputConfig];
      previous[idx].value = '';
      setInputConfig(previous);
    }
  }
};

const onPaste = (
  e: React.ClipboardEvent<HTMLInputElement>,
  inputConfig: InputConfig[],
  setInputConfig: React.Dispatch<React.SetStateAction<InputConfig[]>>,
  length: number,
  mode: Mode
) => {
  e.preventDefault();
  const pasteValues = e.clipboardData.getData('text').slice(0, length);

  if (checkValue(pasteValues, mode, length))
    setInputConfig(
      inputConfig.map((cfg, i) => {
        cfg.inputRef?.current?.blur();
        return { ...cfg, value: pasteValues[i] || '' };
      })
    );
};

export interface VerificationInputProps {
  mode?: Mode;
  hasError?: boolean;
  disabled?: boolean;
  length?: number;
  onChange?: (value: string) => void;
}

type InputConfig = {
  name: string;
  value: string;
  inputRef: React.RefObject<HTMLInputElement>;
};

type Mode = keyof typeof validChars;

export const VerificationInput = ({
  mode = 'number',
  length = DEFAULT_LENGTH,
  onChange,
  hasError,
  ...rest
}: VerificationInputProps) => {
  const [inputConfig, setInputConfig] = useState(
    Array.from({ length }).map((_, i) => ({
      name: `input-${i}`,
      value: '',
      inputRef: createRef<HTMLInputElement>(),
    }))
  );

  useEffect(() => {
    const value = inputConfig.reduce((prev, curr) => prev + curr.value, '');
    if (value.length === length) onChange?.(value);
  }, [inputConfig, onChange, length]);

  useEffect(() => {
    if (hasError) {
      setInputConfig(
        inputConfig.map((cfg, i) => {
          if (i === 0) cfg.inputRef?.current?.focus();
          return { ...cfg, value: '' };
        })
      );
    }
  }, [hasError, onChange]);

  const focusNext = useCallback(
    (idx: number) => {
      if (idx + 1 < inputConfig.length && inputConfig[idx].value) {
        inputConfig[idx + 1].inputRef?.current?.focus();
      }
    },
    [inputConfig]
  );

  const handleChange = useCallback(
    (name: string, e: React.ChangeEvent<HTMLInputElement>) =>
      onInputChange(name, e, inputConfig, setInputConfig, mode),
    [inputConfig, setInputConfig, mode]
  );

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) =>
      onPaste(e, inputConfig, setInputConfig, length, mode),
    [inputConfig, length, mode]
  );

  const handleKeyUp = useCallback(
    (name: string, e: React.KeyboardEvent<HTMLInputElement>) =>
      onKeyUp(name, e, inputConfig, focusNext, mode),
    [focusNext, inputConfig, mode]
  );

  const handleKeyDown = useCallback(
    (name: string, e: React.KeyboardEvent<HTMLInputElement>) =>
      onDelete(name, e, inputConfig, setInputConfig),
    [inputConfig]
  );

  return (
    <div className="flex flex-row gap-x-2 md:gap-x-4">
      {inputConfig.map((cfg) => {
        return (
          <Input
            key={cfg.name}
            ref={cfg.inputRef}
            name={cfg.name}
            value={cfg.value}
            inputMode={inputMode[mode]}
            handleChange={handleChange}
            handlePaste={handlePaste}
            handleKeyUp={handleKeyUp}
            handleKeyDown={handleKeyDown}
            hasError={hasError}
            {...rest}
          />
        );
      })}
    </div>
  );
};

VerificationInput.displayName = 'VerificationInput';
