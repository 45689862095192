import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { FieldErrors, FieldName, FieldValues } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FieldValuesFromFieldErrors } from '@hookform/error-message/dist/types';

import { Icon } from '@components/atoms/Icon';

export type ValidationErrorProps<T extends FieldValues> = {
  id: FieldName<T>;
  errors: FieldErrors<T>;
  className?: string;
  displayAttentionIcon?: boolean;
};

export function ValidationError<T extends FieldValues>({
  id,
  errors,
  className,
  displayAttentionIcon,
}: ValidationErrorProps<T>) {
  const errorWithNoRootKey = Object.keys(errors).reduce((acc, val) => {
    const errObj = errors[val];
    const onlyRootKeyInsideObject = Object.keys(errObj as any).length === 1;

    if (onlyRootKeyInsideObject) {
      // @ts-ignore
      // Set 'root' object values to the top of error object.
      acc[val] = errObj?.root;
    } else {
      // @ts-ignore
      acc[val] = errObj;
    }

    return acc;
  }, {});

  return (
    <p className={twMerge(clsx('mt-2 text-sm text-red-600', className))}>
      <ErrorMessage
        errors={errorWithNoRootKey}
        name={id as FieldName<FieldValuesFromFieldErrors<T>>}
        render={
          displayAttentionIcon
            ? (err) => (
                <div className="flex items-center">
                  <Icon name="attention" />
                  <span className="pl-1">{err.message}</span>
                </div>
              )
            : undefined
        }
      />
    </p>
  );
}
