import { FormEventHandler, ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'next-i18next';

import { Button } from '@components/atoms/Button';
import { PanelTitle } from '@components/atoms/PanelTitle';

export type FormPanelProps = {
  children?: ReactNode;
  className?: string;
  title?: string;
  description?: string;
  onSubmit?: FormEventHandler;
  buttonText?: string;
  buttonIcon?: ReactElement;
  isLoading?: boolean;
};

export const FormPanel = ({
  children,
  className,
  title,
  description,
  onSubmit,
  buttonText,
  buttonIcon,
  isLoading,
}: FormPanelProps) => {
  const { t } = useTranslation('common');
  return (
    <form
      className={twMerge(
        'mt-4 bg-white',
        'w-full rounded-2xl sm:mx-auto',
        'h-full',
        'flex flex-col justify-between',
        className
      )}
      onSubmit={onSubmit}
    >
      <div className={twMerge('px-4 py-4 md:px-10 md:py-6')}>
        {title && (
          <PanelTitle
            className={twMerge(
              description ? 'mb-1 md:mb-2' : '',
              'text-center',
              'text-xl md:text-xl'
            )}
          >
            {title}
          </PanelTitle>
        )}
        {description && (
          <div
            className={twMerge(
              'mb-3 text-sm leading-[1.125rem]',
              'text-center text-neutral-500 md:mb-6'
            )}
          >
            <p>{description}</p>
          </div>
        )}
        {children}
      </div>
      <Button
        className="rounded-b-2xl rounded-t-none"
        fluid
        type="submit"
        loading={isLoading}
        iconBefore={buttonIcon}
      >
        {buttonText || t('next')}
      </Button>
    </form>
  );
};
