import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { clsx } from 'clsx';

export type PanelTitleProps = {
  children?: ReactNode;
  className?: string | null;
  isCentered?: boolean;
};

export function PanelTitle({
  children,
  className,
  isCentered,
}: PanelTitleProps) {
  if (!children) {
    return null;
  }
  return (
    <h2
      className={twMerge(
        clsx(
          'text-xl md:text-2xl',
          'mb-4 font-black leading-6 md:mb-8 md:leading-7',
          isCentered && 'text-center'
        ),
        className
      )}
    >
      {children}
    </h2>
  );
}
