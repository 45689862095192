/* eslint-disable max-len */
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import SimpleBar from 'simplebar-react';
import Image from 'next/image';
import clsx from 'clsx';
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';

import { CountryCodeItem } from 'epromo-types';
import { resolveCountryFlagUrl } from 'epromo-lib/utils/helpers';

import { TextInput } from '@components/atoms/TextInput';
import { Img } from '../Img';

interface Props {
  panelWidth: number | undefined;
  codes: CountryCodeItem[] | undefined;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
}

export const CountryCodesPopover = ({
  panelWidth,
  codes,
  disabled,
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation('common');
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const debouncedChange = useMemo(() => debounce(300, setSearchQuery), []);

  const [countryCodesData, setCountryCodesData] = useState(codes);

  const onPopoverClose = () => {
    setSearchQuery(undefined);
    setCountryCodesData(codes);
  };

  useEffect(() => {
    if (!!searchQuery?.trim()) {
      const filtered = codes?.filter((country) =>
        country.countryName
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase())
      );
      setCountryCodesData([...(filtered ?? [])]);
    } else setCountryCodesData(codes);
  }, [codes, searchQuery]);

  return (
    <Popover>
      <>
        <Popover.Button
          disabled={disabled}
          className="inline-flex items-center disabled:pointer-events-none disabled:text-neutral-500"
        >
          <span className={clsx('pl-4 pr-2 text-sm font-normal')}>{value}</span>
          <ChevronDownIcon className="h-5 w-5" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className="absolute z-10 mt-3"
            style={{ width: `${panelWidth}px` }}
          >
            {({ close }) => (
              <>
                <SimpleBar
                  style={{ height: 300 }}
                  className="rounded-bl-xl rounded-br-xl bg-white p-1 shadow-xl"
                >
                  <div className="relative px-4">
                    <MagnifyingGlassIcon className="absolute left-8 top-[10px] h-5 w-5 text-neutral-500" />

                    <TextInput
                      className="my-4 h-[40px] rounded-3xl pl-12"
                      placeholder={t('enterCountry')}
                      onChange={(ev) => debouncedChange(ev.currentTarget.value)}
                    />
                  </div>

                  {countryCodesData?.map((countryCodeItem) => {
                    const flagUrl = resolveCountryFlagUrl(
                      countryCodeItem.countryImageName
                    );

                    const isSelected = value === countryCodeItem.code;

                    return (
                      <div
                        key={countryCodeItem.id}
                        className={clsx(
                          'relative flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none',
                          isSelected && 'bg-gray-50'
                        )}
                        onClick={() => {
                          onChange(countryCodeItem.code);
                          close();
                          onPopoverClose();
                        }}
                      >
                        <div className="flex items-center">
                          <div className="relative mr-4 h-6 w-8 flex items-center justify-center">
                            {flagUrl === 'NO_FLAG' ? (
                              <div className="h-full w-full rounded-lg bg-neutral-200" />
                            ) : (
                              <Img
                                className="rounded-lg md:rounded-xl object-contain object-center w-auto select-none"
                                alt="img"
                                url={flagUrl}
                                width={32}
                                height={24}
                              />
                            )}
                          </div>
                          <p className="text-sm font-normal text-gray-900">
                            {countryCodeItem.countryName}
                          </p>
                        </div>
                        <p className="text-sm font-normal text-gray-900">
                          {countryCodeItem.code}
                        </p>
                      </div>
                    );
                  })}
                </SimpleBar>
              </>
            )}
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
};
