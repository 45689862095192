import { HTMLAttributes } from 'react';

export interface ImgProps extends HTMLAttributes<HTMLImageElement> {
  url?: string;
  alt?: string;
  height?: number;
  width?: number;
  isLazy?: boolean;
  fetchPriority?: 'high' | 'low' | 'auto';
}

export function Img({
  url,
  alt = '',
  isLazy = false,
  className,
  height,
  width,
  fetchPriority,
  ...rest
}: ImgProps) {
  if (!url) {
    return null;
  }

  const decodedUrl = url; //decodeURI(url);
  let fallbackUrl = new URL(decodedUrl);

  fallbackUrl.searchParams.append('auto', 'format');
  fallbackUrl.searchParams.append('fm', 'avif');

  const smallDeviceUrl = new URL(fallbackUrl.toString());
  smallDeviceUrl.searchParams.append('w', '320');
  const sizes = [320, 480, 1200, 1440];
  const srcSet: string = sizes
    .map((size) => {
      const devUrl = new URL(decodedUrl);
      devUrl.searchParams.append('auto', 'compress');
      devUrl.searchParams.append('fm', 'avif');
      devUrl.searchParams.append('w', `${size}`);
      return `${devUrl.toString()} ${size}w`;
    })
    .join(',');

  return (
    <img
      alt={alt}
      className={className}
      fetchPriority={fetchPriority}
      loading={!isLazy ? 'eager' : 'lazy'}
      src={smallDeviceUrl.toString()}
      srcSet={srcSet}
      sizes="(min-width: 960px) 540px, 320px"
      height={height}
      width={width}
      {...rest}
    />
  );
}
