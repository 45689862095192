import { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Transition } from '@headlessui/react';

import { Icon } from '../Icon';

export enum AlertVariant {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  NOTE = 'note',
  SUCCESS = 'success',
}

const ALERT_VARIANT: Record<
  string,
  { bgColor: string; textColor: string; icon: ReactElement }
> = {
  [AlertVariant.ERROR]: {
    bgColor: 'bg-negative-100',
    textColor: 'text-negative-600',
    icon: <Icon name="attentionFilled" className="text-negative-500" />,
  },
  [AlertVariant.WARNING]: {
    bgColor: 'bg-warning-50',
    textColor: 'text-warning-700',
    icon: <Icon name="warningFilled" className="text-warning-500" />,
  },
  [AlertVariant.INFO]: {
    bgColor: 'bg-business-50',
    textColor: 'text-business-600',
    icon: <Icon name="infoFilled" className="text-business-500" />,
  },
  [AlertVariant.NOTE]: {
    bgColor: 'bg-neutral-100',
    textColor: 'text-neutral-700',
    icon: <Icon name="attentionFilled" className="text-neutral-500" />,
  },
  [AlertVariant.SUCCESS]: {
    bgColor: 'bg-positive-50',
    textColor: 'text-positive-700',
    icon: <Icon name="successFilled" className="text-positive-600" />,
  },
};

export interface AlertProps extends Partial<HTMLDivElement> {
  message?: string | ReactNode | null | undefined;
  type?: AlertVariant;
  icon?: ReactElement;
}

export const Alert = ({
  message,
  type = AlertVariant.ERROR,
  icon,
  className,
}: AlertProps) => {
  const { bgColor, textColor, icon: defaultIcon } = ALERT_VARIANT[type];
  return (
    <Transition show={Boolean(message)}>
      <div
        className={twMerge(
          'flex w-full max-w-md',
          'items-center justify-start gap-2 p-2',
          'textColor rounded-lg',
          bgColor,
          textColor,
          className
        )}
      >
        <div className="shrink-0 self-start">{icon ?? defaultIcon}</div>
        {message && <p className={twMerge('text-xs font-normal')}>{message}</p>}
      </div>
    </Transition>
  );
};
