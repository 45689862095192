import { InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
}

export const INPUT_BASE = [
  'h-12 w-full caret-business-500',
  'text-label text-sm font-normal',
  'placeholder placeholder-neutral-400',
  'box-border rounded border border-neutral-300',
  'active:border-secondary-500 active:border active:placeholder-transparent',
  'focus:border-secondary-500 focus:outline-0 focus:ring-0',
  'focus:border-2 focus:placeholder-transparent',
  'disbled:border-neutral-300 disabled:bg-neutral-100',
  'disabled:pointer-events-none',
  'disabled:text-neutral-500',
];

export const INPUT_ERR = [
  'border-negative-500 bg-negative-50',
  'focus:border-negative-500 active:border-negative-500',
];

export const TextInput = forwardRef<HTMLInputElement, InputProps>(
  ({ type = 'text', hasError, className, name, ...rest }, ref) => (
    <input
      name={name}
      type={type}
      className={twMerge(
        'block p-3',
        INPUT_BASE,
        hasError && INPUT_ERR,
        className
      )}
      aria-invalid={hasError ? 'true' : 'false'}
      aria-describedby={hasError ? `${name}-error` : name}
      {...rest}
      ref={ref}
    />
  )
);

TextInput.displayName = 'TextInput';
