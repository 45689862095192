import React, { ReactElement, ReactNode } from 'react';
import { Control, FieldName, FieldValues, useFormState } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { FormLabel } from '@components/atoms/FormLabel';
import { HelperText } from '@components/atoms/HelperText';

import { ValidationError } from '../ValidationError';

export interface FormFieldProps<T extends FieldValues> {
  label?: string;
  id: FieldName<T>;
  children?: ReactNode;
  control: Control<T>;
  isRequired?: boolean;
  className?: string;
  helperText?: string | ReactNode;
  customValidationComponent?: ReactNode;
}

export function FormField<T extends FieldValues>({
  label,
  children,
  id,
  control,
  isRequired = false,
  className,
  helperText,
  customValidationComponent,
}: FormFieldProps<T>): ReactElement {
  const { errors } = useFormState({
    control,
  });
  // @ts-ignore
  const hasError = errors[id];
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { hasError });
    }
    return child;
  });

  const validationAndHelper = customValidationComponent ?? (
    <>
      {errors && <ValidationError id={id} errors={errors} />}
      {helperText && !hasError ? <HelperText text={helperText} /> : null}
    </>
  );

  return (
    <div className={twMerge('space-y-1', className)}>
      <FormLabel id={id} label={label} isRequired={isRequired} />
      <div className="relative mt-1 rounded-md ">{childrenWithProps}</div>
      {validationAndHelper}
    </div>
  );
}
